<template>
  <v-container
    :fluid="$vuetify.breakpoint.smAndDown"
    class="home"
    :class="{ 'pt-10': $vuetify.breakpoint.smAndUp }"
  >
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-row
        no-gutters
        justify="space-between"
        class="ak-additional-buttons mb-7"
      >
        <v-col cols="1" class="d-flex justify-center">
          <a href="/oauth/logout">
            <mdicon class="ak-logout-icon" name="LogoutVariant" size="26" />
          </a>
        </v-col>
        <v-col cols="1" class="d-flex justify-center" @click="openSettings()">
          <mdicon class="d-block" name="CogOutline" size="26" />
        </v-col>
      </v-row>
      <template v-if="hasUserInfo">
        <v-row no-gutters justify="center" class="flex-column mb-10">
          <v-col
            cols="12"
            class="d-flex justify-center flex-column align-center mb-6"
          >
            <span class="ak-user-name mb-4">
              {{ userInfo.nickname || userInfo.name }}
            </span>
            <AKAvatar
              :hasUserInfo="hasUserInfo"
              :avatar="userInfo.avatar"
              class="ak-user-photo"
              :avatarHeight="119"
              :avatarWidth="119"
            />
          </v-col>
          <v-col
            cols="12"
            class="ak-user-ranks d-flex flex-column justify-center align-center"
          >
            <p class="mb-0">
              <v-rating
                color="yellow"
                background-color="grey"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                half-increments
                length="3"
                readonly
                size="30"
                v-model="userInfo.averageStars"
              ></v-rating>
            </p>
            <p class="mb-0">Rang {{ userInfo.ranking }}</p>
            <p class="mb-0">{{ userInfo.score }} Punkte</p>
          </v-col>
        </v-row>
      </template>
    </template>

    <v-row no-gutters justify="center" class="flex-column mb-10">
      <Point
        :activityProgress="userInfo.percentage"
        activityTitle="Spielen"
        :hasProgressBar="true"
        linkName="LearningUnitsList"
        iconName="games"
      />

      <Point
        activityTitle="Challenges"
        :hasProgressCounts="true"
        linkName="Challenges"
        iconName="challenges"
      />

      <Point activityTitle="Ranking" linkName="Ranking" iconName="ranking" />

      <Point
        activityTitle="Hall of Fame"
        linkName="HallOfFame"
        iconName="hall_of_fame"
        :information-counter="unacknowledgedAwards"
      />
    </v-row>
  </v-container>
</template>

<script>
import AKAvatar from '@/components/common/core/AKAvatar';
import Point from '@/components/common/Point';

export default {
  name: 'Home',
  components: { AKAvatar, Point },
  data() {
    return {
      activityProgress: 33,
      user: {
        name: null,
        photo: null,
      },
    };
  },
  computed: {
    hasUserInfo() {
      return this.$store.state['user'].userInformation.isLoaded;
    },
    userInfo() {
      return this.hasUserInfo
        ? this.$store.state['user'].userInformation.userData
        : null;
    },
    unacknowledgedAwards() {
      return this.hasUserInfo
        ? this.$store.state['user'].userInformation.awards.filter(
            (r) => !r.acknowledged
          ).length
        : 0;
    },
  },
  methods: {
    openSettings() {
      this.$router.push({ name: 'Einstellungen' }).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-additional-buttons {
  .mdi {
    color: #fff;
    cursor: pointer;
  }
  .ak-logout-icon svg {
    transform: rotate(180deg);
  }
}

.ak-user {
  &-name {
    color: #fff !important;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
  &-photo {
    border: 8px solid #f1f0ec !important;
    height: 119px;
    width: 119px;
  }
  &-ranks {
    color: #fff !important;
    display: flex;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
